import React from "react";
// import { Provider } from "react-redux";
// import store from "./redux-store/store";
import Root from "./routes-config";
import "./style.css";

function App() {
  return <Root />;
  // <Provider>
  //<Root />
  // </Provider>
}

export default App;
